import LazyOInviteModal from "~/organizms/O/Invite/Modal.vue";
import LazyOModalAccountPurchaseLimit from "~/organizms/OModal/Account/PurchaseLimit.vue";
import LazyOModalAccountRealityCheckNotification from "~/organizms/OModal/Account/RealityCheckNotification.vue";
import LazyOModalAccountSelfExclusion from "~/organizms/OModal/Account/SelfExclusion.vue";
import LazyOModalAccountChangePassword from "~/organizms/OModal/AccountChangePassword.vue";
import LazyOModalAccountEditContact from "~/organizms/OModal/AccountEditContact.vue";
import LazyOModalAdventCalendarCompleted from "~/organizms/OModal/AdventCalendar/Completed.vue";
import LazyOModalAdventCalendar from "~/organizms/OModal/AdventCalendar/index.vue";
import LazyOModalAdventCalendarIntro from "~/organizms/OModal/AdventCalendar/Intro.vue";
import LazyOModalAdventCalendarRules from "~/organizms/OModal/AdventCalendar/Rules.vue";
import LazyOModalAdventCalendarWelcome from "~/organizms/OModal/AdventCalendar/Welcome.vue";
import LazyOModalBingoHowItWorks from "~/organizms/OModal/Bingo/HowItWorks.vue";
import LazyOModalBingoPlay from "~/organizms/OModal/Bingo/Play.vue";
import LazyOModalBingoProgress from "~/organizms/OModal/Bingo/Progress.vue";
import LazyOModalBingoWelcome from "~/organizms/OModal/Bingo/Welcome.vue";
import LazyOModalBingoWin from "~/organizms/OModal/Bingo/Win.vue";
import LazyOModalDailyPlaybackClaimReward from "~/organizms/OModal/DailyPlayback/ClaimReward.vue";
import LazyOModalDailyPlaybackPromo from "~/organizms/OModal/DailyPlayback/Promo.vue";
import LazyOModalDailyPlaybackRules from "~/organizms/OModal/DailyPlayback/Rules.vue";
import LazyOModalDepositStreakHowItWorks from "~/organizms/OModal/DepositStreak/HowItWorks.vue";
import LazyOModalDepositStreak from "~/organizms/OModal/DepositStreak/index.vue";
import LazyOModalDepositStreakIntro from "~/organizms/OModal/DepositStreak/Intro.vue";
import LazyOModalEmailConfirm from "~/organizms/OModal/EmailConfirm.vue";
import LazyOModalExitOffer from "~/organizms/OModal/ExitOffer.vue";
import LazyOModalFlipTheCoin from "~/organizms/OModal/FlipTheCoin.vue";
import LazyOModalFlipTheCoinHowItWork from "~/organizms/OModal/FlipTheCoinHowItWork.vue";
import LazyOModalFlipTheCoinUsed from "~/organizms/OModal/FlipTheCoinUsed.vue";
import LazyOModalForgotPassword from "~/organizms/OModal/ForgotPassword.vue";
import LazyOModalForgotPasswordFailed from "~/organizms/OModal/ForgotPasswordFailed.vue";
import LazyOModalForgotPasswordPhoneSent from "~/organizms/OModal/ForgotPasswordPhoneSent.vue";
import LazyOModalForgotPasswordSent from "~/organizms/OModal/ForgotPasswordSent.vue";
import LazyOModalFreeSpinAlternative from "~/organizms/OModal/FreeSpin/Alternative.vue";
import LazyOModalFreeSpinCongrats from "~/organizms/OModal/FreeSpin/Congrats.vue";
import LazyOModalFreeSpinFeature from "~/organizms/OModal/FreeSpin/Feature.vue";
import LazyOModalFreeSpinHowItWorks from "~/organizms/OModal/FreeSpin/HowItWorks.vue";
import LazyOModalFreeSpin from "~/organizms/OModal/FreeSpin/index.vue";
import LazyOModalFreeSpinWelcome from "~/organizms/OModal/FreeSpin/Welcome.vue";
import LazyOModalGamePreview from "~/organizms/OModal/Game/GamePreview.vue";
import LazyOModalGame from "~/organizms/OModal/Game/index.vue";
import LazyOModalGamePreviewExclusive from "~/organizms/OModal/Game/PreviewExclusive.vue";
import LazyOModalGameSearch from "~/organizms/OModal/Game/Search.vue";
import LazyOModalGameSwitchToSweeps from "~/organizms/OModal/Game/SwitchToSweeps.vue";
import LazyOModalGetAppDesktop from "~/organizms/OModal/GetApp/Desktop.vue";
import LazyOModalGetAppMain from "~/organizms/OModal/GetApp/Main.vue";
import LazyOModalGetAppSoon from "~/organizms/OModal/GetApp/Soon.vue";
import LazyOModalLeaderboard from "~/organizms/OModal/Leaderboard.vue";
import LazyOModalLogin from "~/organizms/OModal/Login.vue";
import LazyOModalNextStatusSystemLevel from "~/organizms/OModal/NextStatusSystemLevel.vue";
import LazyOModalPhoneConfirmation from "~/organizms/OModal/PhoneConfirmation.vue";
import LazyOModalPhoneConfirmationInfo from "~/organizms/OModal/PhoneConfirmationInfo.vue";
import LazyOModalPresetPackage from "~/organizms/OModal/PresetPackage.vue";
import LazyOModalPrizeDropsHowItWorks from "~/organizms/OModal/PrizeDrops/HowItWorks.vue";
import LazyOModalPrizeDropsTablePrizes from "~/organizms/OModal/PrizeDrops/TablePrizes.vue";
import LazyOModalPrizeDropsWelcome from "~/organizms/OModal/PrizeDrops/Welcome.vue";
import LazyOModalPrizeDropsWin from "~/organizms/OModal/PrizeDrops/Win.vue";
import LazyOModalProfileRewards from "~/organizms/OModal/ProfileRewards.vue";
import LazyOModalQrcode from "~/organizms/OModal/Qrcode.vue";
import LazyOModalQuestActiveMission from "~/organizms/OModal/Quest/ActiveMission.vue";
import LazyOModalQuestCompletedMission from "~/organizms/OModal/Quest/CompletedMission.vue";
import LazyOModalQuestGames from "~/organizms/OModal/Quest/Games.vue";
import LazyOModalQuestHowItWorks from "~/organizms/OModal/Quest/HowItWorks.vue";
import LazyOModalQuestMain from "~/organizms/OModal/Quest/Main.vue";
import LazyOModalRacesGames from "~/organizms/OModal/RacesGames.vue";
import LazyOModalRacesRules from "~/organizms/OModal/RacesRules.vue";
import LazyOModalReferralInfo from "~/organizms/OModal/ReferralInfo.vue";
import LazyOModalRegistrationComplete from "~/organizms/OModal/RegistrationComplete.vue";
import LazyOModalReRegistration from "~/organizms/OModal/ReRegistration.vue";
import LazyOModalRewardsStatusSystem from "~/organizms/OModal/RewardsStatusSystem.vue";
import LazyOModalScratchCardsTicketPlay from "~/organizms/OModal/ScratchCards/TicketPlay.vue";
import LazyOModalScratchCardsWelcome from "~/organizms/OModal/ScratchCards/Welcome.vue";
import LazyOModalScratchCardsYouWon from "~/organizms/OModal/ScratchCards/YouWon.vue";
import LazyOModalSeasonHowItWorks from "~/organizms/OModal/Season/HowItWorks.vue";
import LazyOModalSeasonLeaveSeason from "~/organizms/OModal/Season/LeaveSeason.vue";
import LazyOModalSeasonTower from "~/organizms/OModal/Season/Tower.vue";
import LazyOModalSeasonWelcome from "~/organizms/OModal/Season/Welcome.vue";
import LazyOModalShowPrizes from "~/organizms/OModal/ShowPrizes.vue";
import LazyOModalSignup from "~/organizms/OModal/Signup.vue";
import LazyOModalSignupExitPopup from "~/organizms/OModal/SignupExitPopup.vue";
import LazyOModalStatusSystemGift from "~/organizms/OModal/StatusSystem/Gift.vue";
import LazyOModalStatusSystem from "~/organizms/OModal/StatusSystem/index.vue";
import LazyOModalStatusSystemLevelUp from "~/organizms/OModal/StatusSystem/LevelUp.vue";
import LazyOModalSuccessReferral from "~/organizms/OModal/SuccessReferral.vue";
import LazyOModalTermsDialog from "~/organizms/OModal/TermsDialog.vue";
import LazyOModalVerificationBonus from "~/organizms/OModal/VerificationBonus.vue";
import LazyOModalWelcome from "~/organizms/OModal/Welcome.vue";
import LazyOModalWelcomeStatusSystem from "~/organizms/OModal/WelcomeStatusSystem.vue";
import LazyOModalWheelDaily from "~/organizms/OModal/Wheel/Daily.vue";
import LazyOModalWheelDailyRules from "~/organizms/OModal/Wheel/DailyRules.vue";
import LazyOModalWheelLuckyRules from "~/organizms/OModal/Wheel/LuckyRules.vue";
import LazyOModalWheelLuckySpin from "~/organizms/OModal/Wheel/LuckySpin.vue";
import LazyOModalWithoutDepositBonus from "~/organizms/OModal/WithoutDepositBonus.vue";

export type Modals = {
	LazyOModalForgotPassword: InstanceType<typeof LazyOModalForgotPassword>["$props"];
	LazyOModalForgotPasswordFailed: InstanceType<typeof LazyOModalForgotPasswordFailed>["$props"];
	LazyOModalForgotPasswordSent: InstanceType<typeof LazyOModalForgotPasswordSent>["$props"];
	LazyOModalForgotPasswordPhoneSent: InstanceType<typeof LazyOModalForgotPasswordPhoneSent>["$props"];
	LazyOModalLogin: InstanceType<typeof LazyOModalLogin>["$props"];
	LazyOModalSignup: InstanceType<typeof LazyOModalSignup>["$props"];
	LazyOModalQrcode: InstanceType<typeof LazyOModalQrcode>["$props"];
	LazyOModalReRegistration: InstanceType<typeof LazyOModalReRegistration>["$props"];
	LazyOModalReferralInfo: InstanceType<typeof LazyOModalReferralInfo>["$props"];
	LazyOModalSuccessReferral: InstanceType<typeof LazyOModalSuccessReferral>["$props"];
	LazyOModalAccountChangePassword: InstanceType<typeof LazyOModalAccountChangePassword>["$props"];
	LazyOModalAccountEditContact: InstanceType<typeof LazyOModalAccountEditContact>["$props"];
	LazyOModalAccountRealityCheckNotification: InstanceType<typeof LazyOModalAccountRealityCheckNotification>["$props"];
	LazyOModalAccountPurchaseLimit: InstanceType<typeof LazyOModalAccountPurchaseLimit>["$props"];
	LazyOModalAccountSelfExclusion: InstanceType<typeof LazyOModalAccountSelfExclusion>["$props"];
	LazyOModalEmailConfirm: InstanceType<typeof LazyOModalEmailConfirm>["$props"];
	LazyOModalSignupExitPopup: InstanceType<typeof LazyOModalSignupExitPopup>["$props"];
	LazyOModalPhoneConfirmation: InstanceType<typeof LazyOModalPhoneConfirmation>["$props"];
	LazyOModalPhoneConfirmationInfo: InstanceType<typeof LazyOModalPhoneConfirmationInfo>["$props"];
	LazyOModalGame: InstanceType<typeof LazyOModalGame>["$props"];
	LazyOModalGamePreview: InstanceType<typeof LazyOModalGamePreview>["$props"];
	LazyOModalGameSwitchToSweeps: InstanceType<typeof LazyOModalGameSwitchToSweeps>["$props"];
	LazyOModalRacesGames: InstanceType<typeof LazyOModalRacesGames>["$props"];
	LazyOModalStatusSystem: InstanceType<typeof LazyOModalStatusSystem>["$props"];
	LazyOModalStatusSystemLevelUp: InstanceType<typeof LazyOModalStatusSystemLevelUp>["$props"];
	LazyOModalStatusSystemGift: InstanceType<typeof LazyOModalStatusSystemGift>["$props"];
	LazyOModalWelcomeStatusSystem: InstanceType<typeof LazyOModalWelcomeStatusSystem>["$props"];
	LazyOModalRewardsStatusSystem: InstanceType<typeof LazyOModalRewardsStatusSystem>["$props"];
	LazyOModalPresetPackage: InstanceType<typeof LazyOModalPresetPackage>["$props"];
	LazyOModalNextStatusSystemLevel: InstanceType<typeof LazyOModalNextStatusSystemLevel>["$props"];
	LazyOModalVerificationBonus: InstanceType<typeof LazyOModalVerificationBonus>["$props"];
	LazyOModalSeasonWelcome: InstanceType<typeof LazyOModalSeasonWelcome>["$props"];
	LazyOModalSeasonHowItWorks: InstanceType<typeof LazyOModalSeasonHowItWorks>["$props"];
	LazyOModalSeasonLeaveSeason: InstanceType<typeof LazyOModalSeasonLeaveSeason>["$props"];
	LazyOModalSeasonTower: InstanceType<typeof LazyOModalSeasonTower>["$props"];
	LazyOModalFlipTheCoin: InstanceType<typeof LazyOModalFlipTheCoin>["$props"];
	LazyOModalFlipTheCoinHowItWork: InstanceType<typeof LazyOModalFlipTheCoinHowItWork>["$props"];
	LazyOModalFlipTheCoinUsed: InstanceType<typeof LazyOModalFlipTheCoinUsed>["$props"];
	LazyOModalQuestGames: InstanceType<typeof LazyOModalQuestGames>["$props"];
	LazyOModalQuestHowItWorks: InstanceType<typeof LazyOModalQuestHowItWorks>["$props"];
	LazyOModalQuestActiveMission: InstanceType<typeof LazyOModalQuestActiveMission>["$props"];
	LazyOModalQuestCompletedMission: InstanceType<typeof LazyOModalQuestCompletedMission>["$props"];
	LazyOModalQuestMain: InstanceType<typeof LazyOModalQuestMain>["$props"];
	LazyOInviteModal: InstanceType<typeof LazyOInviteModal>["$props"];
	LazyOModalGetAppDesktop: InstanceType<typeof LazyOModalGetAppDesktop>["$props"];
	LazyOModalGetAppMain: InstanceType<typeof LazyOModalGetAppMain>["$props"];
	LazyOModalGetAppSoon: InstanceType<typeof LazyOModalGetAppSoon>["$props"];
	LazyOModalWelcome: InstanceType<typeof LazyOModalWelcome>["$props"];
	LazyOModalWheelDaily: InstanceType<typeof LazyOModalWheelDaily>["$props"];
	LazyOModalWheelLuckySpin: InstanceType<typeof LazyOModalWheelLuckySpin>["$props"];
	LazyOModalWheelDailyRules: InstanceType<typeof LazyOModalWheelDailyRules>["$props"];
	LazyOModalWheelLuckyRules: InstanceType<typeof LazyOModalWheelLuckyRules>["$props"];
	LazyOModalRacesRules: InstanceType<typeof LazyOModalRacesRules>["$props"];
	LazyOModalShowPrizes: InstanceType<typeof LazyOModalShowPrizes>["$props"];
	LazyOModalLeaderboard: InstanceType<typeof LazyOModalLeaderboard>["$props"];
	LazyOModalTermsDialog: InstanceType<typeof LazyOModalTermsDialog>["$props"];
	LazyOModalRegistrationComplete: InstanceType<typeof LazyOModalRegistrationComplete>["$props"];
	LazyOModalPrizeDropsWelcome: InstanceType<typeof LazyOModalPrizeDropsWelcome>["$props"];
	LazyOModalPrizeDropsHowItWorks: InstanceType<typeof LazyOModalPrizeDropsHowItWorks>["$props"];
	LazyOModalPrizeDropsWin: InstanceType<typeof LazyOModalPrizeDropsWin>["$props"];
	LazyOModalPrizeDropsTablePrizes: InstanceType<typeof LazyOModalPrizeDropsTablePrizes>["$props"];
	LazyOModalProfileRewards: InstanceType<typeof LazyOModalProfileRewards>["$props"];
	LazyOModalAdventCalendarWelcome: InstanceType<typeof LazyOModalAdventCalendarWelcome>["$props"];
	LazyOModalAdventCalendar: InstanceType<typeof LazyOModalAdventCalendar>["$props"];
	LazyOModalScratchCardsWelcome: InstanceType<typeof LazyOModalScratchCardsWelcome>["$props"];
	LazyOModalScratchCardsTicketPlay: InstanceType<typeof LazyOModalScratchCardsTicketPlay>["$props"];
	LazyOModalScratchCardsYouWon: InstanceType<typeof LazyOModalScratchCardsYouWon>["$props"];
	LazyOModalAdventCalendarCompleted: InstanceType<typeof LazyOModalAdventCalendarCompleted>["$props"];
	LazyOModalDepositStreakHowItWorks: InstanceType<typeof LazyOModalDepositStreakHowItWorks>["$props"];
	LazyOModalDepositStreakIntro: InstanceType<typeof LazyOModalDepositStreakIntro>["$props"];
	LazyOModalDepositStreak: InstanceType<typeof LazyOModalDepositStreak>["$props"];
	LazyOModalGamePreviewExclusive: InstanceType<typeof LazyOModalGamePreviewExclusive>["$props"];
	LazyOModalBingoWelcome: InstanceType<typeof LazyOModalBingoWelcome>["$props"];
	LazyOModalBingoProgress: InstanceType<typeof LazyOModalBingoProgress>["$props"];
	LazyOModalBingoHowItWorks: InstanceType<typeof LazyOModalBingoHowItWorks>["$props"];
	LazyOModalBingoPlay: InstanceType<typeof LazyOModalBingoPlay>["$props"];
	LazyOModalBingoWin: InstanceType<typeof LazyOModalBingoWin>["$props"];
	LazyOModalFreeSpinHowItWorks: InstanceType<typeof LazyOModalFreeSpinHowItWorks>["$props"];
	LazyOModalFreeSpinWelcome: InstanceType<typeof LazyOModalFreeSpinWelcome>["$props"];
	LazyOModalFreeSpinCongrats: InstanceType<typeof LazyOModalFreeSpinCongrats>["$props"];
	LazyOModalFreeSpin: InstanceType<typeof LazyOModalFreeSpin>["$props"];
	LazyOModalFreeSpinAlternative: InstanceType<typeof LazyOModalFreeSpinAlternative>["$props"];
	LazyOModalFreeSpinFeature: InstanceType<typeof LazyOModalFreeSpinFeature>["$props"];
	LazyOModalExitOffer: InstanceType<typeof LazyOModalExitOffer>["$props"];
	LazyOModalGameSearch: InstanceType<typeof LazyOModalGameSearch>["$props"];
	LazyOModalWithoutDepositBonus: InstanceType<typeof LazyOModalWithoutDepositBonus>["$props"];
	LazyOModalAdventCalendarRules: InstanceType<typeof LazyOModalAdventCalendarRules>["$props"];
	LazyOModalAdventCalendarIntro: InstanceType<typeof LazyOModalAdventCalendarIntro>["$props"];
	LazyOModalDailyPlaybackRules: InstanceType<typeof LazyOModalDailyPlaybackRules>["$props"];
	LazyOModalDailyPlaybackClaimReward: InstanceType<typeof LazyOModalDailyPlaybackClaimReward>["$props"];
	LazyOModalDailyPlaybackPromo: InstanceType<typeof LazyOModalDailyPlaybackPromo>["$props"];
};

const useFunrizeModals = () => useModals<Modals>();

export default useFunrizeModals;
